import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import VideoBanner from "../components/VideoBanner"
// import vid3 from "../assets/videos/vid3.mp4"

const AboutTheFarms = ({ data }) => {
  const { strapiAboutTheFarmsPage: info } = data

  const path = getImage(info.farms_image1?.localFile)
  const path2 = getImage(info.farms_info_background_image?.localFile)

  return (
    <Layout>
      <Seo Sitetitle="About The Farms" />
      <VideoBanner
        heading={info.banner_heading}
        para={info.banner_description}
        vid={info.banner_video.url}
      />
      <Container className="my-5 text-center">
        <h2 className="my-2">{info.farms_heading}</h2>
        <p>{info.farms_description}</p>
        <button className="btn btn-yellow my-4">VISIT US</button>
      </Container>
      <div className="banner-container">
        <GatsbyImage image={path2} alt="banner-img" className="about-img" />
        <div className="about-container">
          <Container className="p-3">
            <Row className="justify-content-center align-items-center">
              <Col lg={6} className="text-center text-white">
                <h1>{info.farms_heading1}</h1>
                <p>{info.farms_description1}</p>
              </Col>
              <Col lg={6}>
                <GatsbyImage
                  image={path}
                  alt="banner-img"
                  className="operation-img"
                />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </Layout>
  )
}

export const data = graphql`
  {
    strapiAboutTheFarmsPage {
      banner_heading
      banner_description
      banner_video {
        url
      }
      farms_heading
      farms_description
      farms_heading1
      farms_description1
      farms_info_background_image {
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: TRACED_SVG)
          }
        }
      }
      farms_image1 {
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: TRACED_SVG)
          }
        }
      }
    }
  }
`

export default AboutTheFarms
